<template>
  <nav class="navbar navbar-expand-lg translucent-navbar">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">francesca</a>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleNavbar"
        aria-controls="navbarSupportedContent"
        aria-expanded="isNavbarCollapsed"
        aria-label="Toggle navigation">
        <i class="fas fa-bars"></i> <!-- Use FontAwesome icon for hamburger -->
      </button>
      <div :class="['collapse', 'navbar-collapse', { show: !isNavbarCollapsed }]" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/" @click="closeNavbar">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about" @click="closeNavbar">About</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/portfolio" @click="closeNavbar">Portfolio</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact" @click="closeNavbar">Contact Me</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isNavbarCollapsed: true, // Track navbar state
    };
  },
  methods: {
    toggleNavbar() {
      this.isNavbarCollapsed = !this.isNavbarCollapsed; // Toggle state
    },
    closeNavbar() {
      this.isNavbarCollapsed = true; // Collapse navbar
    }
  }
};
</script>

<style>



/* Navbar styling */
.translucent-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(195, 195, 195, 0.811);
  z-index: 1000; /* Ensure it is on top of other elements */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  backdrop-filter: blur(10px); /* Adds a blur effect to the translucent background */
}

/* Ensure the toggle button is visible */
.navbar-toggler {
  border: none; /* Remove default border */
  background-color: transparent; /* Transparent background */
  font-size: 24px; /* Increase icon size */
}

/* Style the FontAwesome icon */
.navbar-toggler i {
  color: #2c3e50; /* Change icon color to match the theme */
}

/* Hover effect for the toggle button */
.navbar-toggler:hover {
  background-color: rgba(44, 62, 80, 0.1); /* Light background on hover for better visibility */
}


/* Set font family */
.navbar-nav .nav-link {
  font-family: "Montserrat";
}

/* Responsive background on mobile */
@media (max-width: 768px) {
  .translucent-navbar {
    background-color: rgba(195, 195, 195, 1); /* Solid background on mobile */
  }
}

.navbar-brand{
  font-family: 'Gallery', sans-serif;
  font-size: 1.6rem;
}

.navbar-brand,
.nav-link {
  color: #2c3e50 !important; /* Ensures the links are visible */
}

.navbar-nav .nav-link:hover {
  color: rgb(126, 174, 98) !important; /* Change color on hover */
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
      padding-top: .3125rem;
  }
}

/* Add padding to the main content to account for the fixed navbar */
body {
  padding-top: 70px; /* Adjust this value based on the height of the navbar */
}
</style>
