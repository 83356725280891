<template>
  <div id="app">

    <!-- NavBar that hovers over the page content -->
    <NavBar />

    <!-- <SinglePage></SinglePage> -->
    <router-view></router-view>

  </div>
</template>

<script>
// Importing NavBar
import NavBar from './components/NavBar.vue';
import SinglePage from './views/SinglePage.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    SinglePage
  },
};
</script>

<style lang="scss">

// Main Settings

body{
  padding: 0;
  width: 100%;
  height: 100%;
}

html, body {
  height: 100%;
  margin: 0; /* Remove default margin */
}


// Fonts

@font-face {
  font-family: 'Gallery';
  src: url('@/assets/Gallery-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

a,p,h4,h5{
  font-family: "Montserrat";
}

p.hero-paragraph {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-family: "Montserrat";
}

h1.hero-heading {
  font-size: 3.5rem;
  color: antiquewhite; /* Slightly off-white color */
  font-family: "Montserrat";
}

.pt-sans-regular {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.pt-sans-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.pt-sans-regular-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.pt-sans-bold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}


.btn-fk-lnd {
  color: white;
  background-color: rgb(74,74,74);
  border-color: rgb(74, 74, 74);
}

.btn-fk-lnd:hover {
  color: rgb(186, 182, 182);
}



</style>
